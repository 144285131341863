(function($) {

    /**
     * Responsive Slick Slider window resize/unslick functionality
     * Solution from jmarcelli : https://github.com/kenwheeler/slick/issues/542#issuecomment-168658956
     */
    $home_slider = $('.slider-home');
    home_settings = {
        adaptiveHeight: true,
        autoplay: false,
        autoplaySpeed: 5000,

        dots: true,
        fade: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        
        
        prevArrow: '<button type="button" class="slick-prev"><span>Prev</span></button>',
        nextArrow: '<button type="button" class="slick-next"><span>Next</span></button>',
        // responsive: [
        //     {
        //         breakpoint: 768,
        //         settings: {
        //             slidesToShow: 1,
        //             slidesToScroll: 1,
        //             infinite: true,
        //             dots: true
        //         }
        //     },
        //     // You can unslick at a given breakpoint now by adding:
        //     // settings: "unslick"
        //     // instead of a settings object
        // ]
    };
    $home_slider.slick(home_settings);

    // reslick only if it's not slick()
    // $(window).on('resize', function() {
    //     if ($(window).width() < 768) {
    //         if ($slick_slider.hasClass('slick-initialized')) {
    //             $slick_slider.slick('unslick');
    //         }
    //         return;
    //     }

    //     if (!$slick_slider.hasClass('slick-initialized')) {
    //         return $slick_slider.slick(settings);
    //     }
    // });
    
        /**
     * Responsive Slick Slider window resize/unslick functionality
     * Solution from jmarcelli : https://github.com/kenwheeler/slick/issues/542#issuecomment-168658956 
     */
    $product_slider = $('.cocktail__single__outer');
    product_settings = {
        infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: '<button type="button" class="slick-prev cocktail__button__prev"><span>Prev</span></button>',
            nextArrow: '<button type="button" class="slick-prev cocktail__button__next"><span>Next</span></button>',
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: "unslick",
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
    };
    $product_slider.slick(product_settings);

    // reslick only if it's not slick()
    $(window).on('resize', function() {
        if ($(window).width() < 768) {
            if ($product_slider.hasClass('slick-initialized')) {
                $product_slider.slick('unslick');
            }
            return;
        }

        if (!$product_slider.hasClass('slick-initialized')) {
            return $product_slider.slick(product_settings);
        }
    });
})(jQuery); // Fully reference jQuery after this point.

